import { Trans, useTranslation } from 'next-i18next'
import Image from 'next/image'
import { Card } from '~/components/ui/card'
import { SectionTitle } from '~/domains/home/components/section-title'
import { SectionWrapper } from '~/domains/home/components/section-wrapper'

export const TeamSection = () => {
	const { t } = useTranslation()

	return (
		<div className='bg-white'>
			<SectionWrapper>
				<SectionTitle>{t('cwHome:team.title')}</SectionTitle>

				<div className='flex flex-col gap-6 md:flex-row'>
					<div className='order-2 flex flex-1 flex-col justify-center gap-6 md:order-1'>
						<Trans
							parent='div'
							className='text-lg'
							i18nKey='cwHome:team.body1'
						/>

						<Trans
							parent='div'
							i18nKey='cwHome:team.body2'
						/>

						<Trans
							parent='div'
							i18nKey='cwHome:team.body3'
						/>
					</div>

					<Card className='relative order-1 aspect-video overflow-hidden rounded bg-cover md:order-2 md:w-[550px]'>
						<Image
							src='/images/team.jpg'
							alt=''
							fill
							className='z-0 object-cover object-left'
						/>
					</Card>
				</div>
			</SectionWrapper>
		</div>
	)
}
