import { useListSpaces } from '@cocoonspace/sdk-js/domains/spaces/spaces'
import type { Space } from '@cocoonspace/sdk-js/types/space'
import { useAuth } from '@cocoonspace/shared/domains/auth/hooks/use-auth.hook'
import type { NextPage } from 'next'
import { useTranslation } from 'next-i18next'
import Head from 'next/head'
import Link from 'next/link'
import { useRouter } from 'next/router'
import { useEffect } from 'react'
import { SpaceCarousel } from '~/components/organisms/space-carousel'
import { buttonVariants } from '~/components/ui/button'
import { HomeIntro } from '~/domains/home/components/home-intro'
import { HomeTestimonials } from '~/domains/home/components/home-testimonials'
import { HowCocoonDesk } from '~/domains/home/components/how-cocoon-desk'
import { SectionTitle } from '~/domains/home/components/section-title'
import { SectionWrapper } from '~/domains/home/components/section-wrapper'
import { TeamSection } from '~/domains/home/components/team-section'
import { cn } from '~/lib/utils'

const jsonLD = {
	'@context': 'https://schema.org',
	'@type': 'Organization',
	url: 'https://www.cocoon-space.com',
	logo: 'https://www.cocoon-space.com/images/v2/logo.png',
}

const SPACES_IDS = [
	'9541f3be-d6f0-4489-b398-a0ddc25abc2f',
	'8bb0caf8-9785-4b04-a13a-66652ce82df9',
	'a1aeff86-7fd3-4615-b5f9-3e8b37451d6e',
	'ad605b8a-7bf8-4b00-92d1-889ff7c10c42',
	'a5e156a6-3da3-4935-8274-04793fc7aa16',
	'ab3c8cce-cc7f-4432-84cc-c30acd526a78',
	'80c8418b-eb05-4f5f-87f5-a039b87b669b',
	'4ae9bd31-d873-4bfd-ad82-172886570791',
	'0ab47214-726f-4a43-965f-785a5db78a28',
]

export const HomePage: NextPage = () => {
	const router = useRouter()
	const { t } = useTranslation()

	const { isLoading, isAuth } = useAuth()

	const { data: spaces } = useListSpaces(
		{
			ids: SPACES_IDS,
			zone: 'paris',
			limit: 9,
		},
		{
			query: {
				select: (spaces) =>
					SPACES_IDS.reduce((acc, spaceId) => {
						const space = spaces.data.find(({ id }) => id === spaceId)

						if (space) {
							acc.push(space)
						}

						return acc
					}, [] as Space[]),
			},
		},
	)

	useEffect(() => {
		if (isAuth && !isLoading) {
			router.push('/dashboard')
		}
	}, [isAuth, isLoading, router])

	if (isAuth && !isLoading) return <div className='min-h-[80vh]' />

	return (
		<>
			<Head>
				<script
					dangerouslySetInnerHTML={{ __html: JSON.stringify(jsonLD) }}
					type='application/ld+json'
				/>
			</Head>

			<HomeIntro />

			<HowCocoonDesk />

			<HomeTestimonials />

			<SectionWrapper>
				<SectionTitle>{t('cwHome:findYourCocoon.title')}</SectionTitle>

				<SpaceCarousel spaces={spaces || []} />
			</SectionWrapper>

			<TeamSection />

			<SectionWrapper>
				<SectionTitle>{t('cwHome:quotation.title')}</SectionTitle>

				<Link
					href='https://cocoonspace.typeform.com/to/ekCM8SS9'
					target='_blank'
					className={cn(
						buttonVariants({
							variant: 'outlinePrimary',
							className: 'self-center',
						}),
					)}
				>
					{t('cwHome:quotation.btnLabel')}
				</Link>
			</SectionWrapper>
		</>
	)
}
